import utils from 'hey-utils';
import {isNumber} from 'xe-utils';
import {toMoney} from "number-string";

const rclass = /[\t\r\n\f]/g;

export const CopyObj = (target, original) => {
  if (original) {
    //解除obs
    original = structuredClone(original);
    Object.keys(target).forEach(key => {
      target[key] = original[key];
    });
  }
}

export const ArrToObj = (target, getKey, getVal) => {
  let obj = {};
  if (target) {
    for (const item of target) {
      obj[getKey(item)] = getVal ? getVal(item) : item;
    }
  }
  return obj;
}

/**
 * 金额格式化
 * @param cellValue
 * @param defVal
 * @returns {string|string}
 * @constructor
 */
export const ToMoney = (cellValue, defVal = '') => {
  return cellValue && cellValue !== 0 ? toMoney(cellValue, {
    maxPrecision: 2,
    useParens: false,
    symbol: ''
  }) : defVal;
}

export const ToNumNumber = (cellValue, defVal = '') => {
  if (!cellValue || cellValue === '0') {
    return defVal || ''
  }
  return toMoney(cellValue, {
    maxPrecision: 5,
    minPrecision: 2,
    useParens: false,
    symbol: ''
  });
}

export const DxMoney = (number, defVal = '') => {
  if (!isNumber(number) || number === 0) {
    return "零元整";
  }

  let n = Math.abs(number);

  let unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
  n += "00";
  let p = n.indexOf('.');
  if (p >= 0) {
    n = n.substring(0, p) + n.substr(p + 1, 2);
  }
  unit = unit.substring(unit.length - n.length);
  for (let i = 0; i < n.length; i++) {
    str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
  }

  return (number < 0 ? '负' : '') + str.replace(/零([仟佰拾角])/g, "零").replace(/(零)+/g, "零").replace(/零([万亿元])/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
}


export default utils.extend({}, utils, {
  getClass(elem) {
    return (elem.getAttribute && elem.getAttribute('class')) || '';
  },
  hasClass(elem, selector) {
    let className;
    className = ` ${selector} `;
    if (elem.nodeType === 1 && (` ${this.getClass(elem)} `)
      .replace(rclass, ' ')
      .indexOf(className) > -1) {
      return true;
    }

    return false;
  }
});
